

@media (min-width: 540px) {
  .frost {
    height:42vh;
    width:40vw;
    /* background-color: white; */
    background-image:linear-gradient(to bottom right, rgba(255,255,255,0.1), rgba(255,255,255,0.3), rgba(255,255,255,0.1));
    border-radius:3em;
    backdrop-filter: "blur(10px)"; 
    /* box-shadow: 10px 0px 10px 0px rgba(255,255,255,0.3); */
  
  }

  .carousel{
    width:42.5vh;
  }

  .mobile{
    width:12vw;
  }

  .mobile2{
    width:12vw;
  }

  .background{
    min-height:100vh;
  }


  .container {
    display:flex;
    flex-direction:row;
    justify-content:space-around;
  }
}


@media (max-width: 540px) {


  .frost {
    height:40vh;
    width:80vw;
    /* background-color: white; */
    background-image:linear-gradient(to bottom right, rgba(255,255,255,0.1), rgba(255,255,255,0.3), rgba(255,255,255,0.1));
    border-radius:3em;
    backdrop-filter: "blur(10px)"; 
  }

  .mobile{
    width:10vw;
  }

  .mobile2{
    width:10vw;
  }

  .background{
    min-height:100vh;
  }


  .container {
    display:flex;
    flex-direction:column;
    justify-content:center;
    align-items:center;
  }
}

