.App {
    text-align: center;
    display: flex;
    flex-direction: column;
    background-color: #414141;
    min-height: 100vh;
  }
  
  .App-logo {
    height: 40vmin;
    pointer-events: none;
  }
  
  .logo {
    height: 7vmin;
    margin: 5px;
  }
  
  .header-options {
    font-size: 1.2vw;
    margin-bottom: 0.5vh;
    font-family: Didact Gothic;
    letter-spacing:0.2vw;
  }
  
  .social-button {
    background-color: #3e7652;
    height: 3vh;
    width: 6vw;
    border-radius: 3vh;
    border: none;
    margin: 2px;
    color: white;
    font-size: 1vw;
    font-family: Didact Gothic;
    letter-spacing:0.2vw
  }
  
  .background-img {
    width: 100vw;
    opacity: 40%;
  }
  
  @media (prefers-reduced-motion: no-preference) {
    .App-logo {
      animation: App-logo-spin infinite 20s linear;
    }
  }
  
  .App-header {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: space-evenly;
    font-size: calc(10px + 2vmin);
    color: white;
    background-color: #414141;
    height: 90px;
  }
  
  .App-link {
    color: #61dafb;
  }
  
  @keyframes App-logo-spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }


  /* @media (max-width:600px)  {
    .App {
      background-color:red !important;
      flex-direction: row;
    }
    
    .text{
      font-size:15px !important;
    }


    #roadmap {
      background-color:red !important;
    }

    #textbox {
      width: 60% !important;
      margin-top: 0vh !important;
    }

    #container-content{
      flex-direction: column !important;
      align-items: center !important;
    }
  } */

  